#root {
    height: 100vh;
    width: 100%;
    font-family: "Segoe UI", sans-serif;
}

.container {
    padding: 40px;
    background: #419be0;
}

.slick-slide img {
    margin: auto;
}

.bg {
    background-position: 50%;
    background-attachment: fixed;
    background-color: white;
    background-size: cover;
}

.darkbg {
    background-color: rgba(255, 255, 255, 0.8);
}

.earth {
    background-image: url("assets\\earth_2024.jpg");
    background-position: -1000px;
    background-repeat: no-repeat;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(46, 78, 123);
    border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
}
