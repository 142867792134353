.pulsing-icon {    
    border: 1px solid red;
    border-radius: 20px;
    height: 18px;
    width: 18px ;
    animation: pulsate 1s;
    animation-iteration-count: infinite; 
}   
@keyframes pulsate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}